import { IconComponent } from '@amaris/lib-highway/components/icon';
import { AsyncPipe, CommonModule } from '@angular/common';
import {
  AfterViewInit,
  Component, EventEmitter, Input, OnInit, Output,
} from '@angular/core';
import { LanguageSelectorComponent } from '@amaris/lib-highway/components/langage-selector';
import { AuthentificationService, ThemeService } from '@amaris/lib-highway/services';
import { ThemeModel, TokenModel } from '@amaris/lib-highway/models';
import { RouterLink } from '@angular/router';
import { AuthService, LogoutOptions } from '@auth0/auth0-angular';
import { SafeHtmlPipe, TranslatePipe } from '@amaris/lib-highway/pipes';
import { NgSelectModule } from '@ng-select/ng-select';
import { FormsModule } from '@angular/forms';
import { desktopLogo, mobileLogo } from './svg-logo';
import { AppWideSearchInputComponent } from '../../design/app-wide-search-input/app-wide-search-input.component';
import { UserInitializationService } from '../../../services/user-initialization/user-initialization.service';
import { User } from '../../../models/user.model';
import { UsersApiService } from '../../../services/apis/users-api/users-api.service';
import { ApiResponse } from '../../../models/api-response.model';
import { MedicalPracticesService } from '../../../services/apis/mediacal-practices/mediacal-practices.service';
import { DataStoreService } from '../../../services/data-store/data-store.service';

@Component({
  selector: 'headband',
  standalone: true,
  imports: [
    CommonModule,
    IconComponent,
    FormsModule,
    RouterLink,
    LanguageSelectorComponent,
    AppWideSearchInputComponent,
    RouterLink,
    TranslatePipe,
    NgSelectModule,
    AsyncPipe,
    SafeHtmlPipe,
  ],
  templateUrl: './headband.component.html',
  styleUrl: './headband.component.scss',
})
export class HeadbandComponent implements OnInit, AfterViewInit {
  @Input() notificationsVisible: boolean = false;
  @Output() userInteractedWithNotifications: EventEmitter<boolean> = new EventEmitter<boolean>();

  public userDrawerVisible: boolean = false;
  public availableThemes: ThemeModel[] = [];

  public desktopLogo: string = desktopLogo;
  public mobileLogo: string = mobileLogo;

  public token: TokenModel;
  public userFirstname: string;
  public userLastname: string;
  mailAddress = 'supportocea@plaiad.com';

  private logoutOptions: LogoutOptions = {
    logoutParams: {
      returnTo: `${window.location.origin}`,
    },
  };

  handleSupportClick() {
    this.userDrawerVisible = false;
    window.location.href = `mailto:${this.mailAddress}`;
  }

  public defaultPicture: string = '/assets/placeholders/user_picture_placeholder.svg';
  public dataStore: DataStoreService = this.dataStoreService;

  constructor(
    private readonly themeService: ThemeService,
    private readonly authentificationService: AuthentificationService,
    private readonly authService: AuthService,
    private readonly userInitializationService: UserInitializationService,
    private readonly usersApiService: UsersApiService,
    private readonly medicalPracticesService: MedicalPracticesService,
    private readonly dataStoreService: DataStoreService,
  ) {}

  ngOnInit(): void {
    this.userInitializationService.userInitialized.subscribe((user: User) => {
      this.token = this.authentificationService.getToken();
      if (user?.firstname) {
        this.userFirstname = user.firstname;
      } else {
        this.userFirstname = this.token?.firstName;
      }

      if (user?.lastname) {
        this.userLastname = user.lastname;
      } else {
        this.userLastname = this.token?.lastName;
      }

      this.medicalPracticesService.getAll().subscribe();
    });

    this.availableThemes = this.themeService.availableThemes;
    if (!this.availableThemes?.length) {
      this.themeService.availableThemesLoaded$.subscribe((themes: ThemeModel[]) => {
        this.availableThemes = themes;
      });
    }
  }

  toggleMobileMenu() {
    this.dataStoreService.toggleMobileMenu();
  }

  ngAfterViewInit(): void {
    window.addEventListener('click', (event) => {
      const clickedElem = event.target as HTMLElement;

      if (this.userDrawerVisible) {
        let clickedInsideOperator = clickedElem.closest('.user-drawer') != null;
        if (!clickedInsideOperator) {
          clickedInsideOperator = clickedElem.closest('#user-info-drawer') != null;
          if (!clickedInsideOperator) {
            this.userDrawerVisible = false;
          }
        }
      }
    });
  }

  logout() {
    this.authentificationService.clearToken();
    this.authService.logout(this.logoutOptions);
  }

  themeUpdated(event: Event) {
    const target = event.target as HTMLSelectElement;
    this.themeService.changeTheme(target.value);
  }

  interactWithNotifications() {
    this.userInteractedWithNotifications.emit();
  }

  toggleUserDrawer() {
    this.userDrawerVisible = !this.userDrawerVisible;
  }

  switchMedicalPractice(id: string) {
    this.usersApiService.switchMedicalPractice(id).subscribe((response: ApiResponse<any>) => {
      if (response.success) {
        window.location.reload();
      }
    });
  }

  signUp() {
    const options: any = {
      authorizationParams: {
        screen_hint: 'signup',
      },
    };
    this.authService.loginWithRedirect(options);
  }
}
