<div class="drawer" id="user-info-drawer" [ngClass]="{'visible': userDrawerVisible}">
    @if (this.token?.token) {
        <div class="info user-info">{{ userFirstname }}</div>
        <div class="info user-info">{{ userLastname }}</div>
        <div class="separator"></div>
        @if ((dataStore.medialPractices$ | async).length > 1) {
            <div class="input-container ng-select">
                <ng-select
                        class="input"
                        id="type"
                        appendTo="body"
                        class="custom-select"
                        [items]="dataStore.medialPractices$ | async"
                        [clearable]="false"
                        bindLabel="companyName"
                        bindValue="id"
                        [ngModel]="(dataStore.currentMedialPractice$ | async).id"
                        (ngModelChange)="switchMedicalPractice($event)">
                    </ng-select>
                    <hw-icon class="input-icon" icon="fasChevronDown"></hw-icon>
            </div>
        } @else {
            <div class="info cabinet-info">{{ (dataStore.medialPractices$ | async)[0]?.companyName }}</div>
        }
        <div class="separator"></div>
        <a class="button" routerLink="/profile" (click)="userDrawerVisible = false">{{ 'core.keywords.my_profile' | translate }}</a>
        <a class="button" (click)="handleSupportClick()">{{ 'core.keywords.contact_support' | translate }}</a>
        <!-- <hw-langage-selector></hw-langage-selector>
        <select id="theme" (change)="themeUpdated($event)">
            @for (theme of availableThemes; track $index) {
                <option [value]="theme.name">{{ theme.name }}</option>
            }
        </select> -->
        <button class="button error" (click)="logout()">{{ 'core.keywords.logout' | translate }}</button>
    } @else {
        <a class="button" routerLink="/" (click)="userDrawerVisible = false">{{ 'core.keywords.login' | translate }}</a>
        <a class="button" (click)="signUp()" (click)="userDrawerVisible = false">{{ 'core.keywords.sign_up' | translate }}</a>
    }
</div>
<div class="headband-container">
    <div class="left-side">
        <a routerLink="/">
            <span class="hide-mobile-flex logo-container" [innerHTML]='desktopLogo | safeHtml'></span>
            <span class="show-mobile-flex logo-container" [innerHTML]='mobileLogo | safeHtml'></span>
        </a>           
    </div>
    <div class="burger-button show-mobile">
        <div class="container">
            <hw-icon [icon]="(dataStore.mobileMenuVisible$ | async) ? 'fasTimes' : 'fasBars'" (click)="toggleMobileMenu()"></hw-icon>
        </div>
    </div>
    <div class="center">
        @if (this.token?.token) {
            <app-wide-search-input placeholder="core.headband.search" name="appWideSearchInput"></app-wide-search-input>
        }
    </div>
    <div class="right-side">
        <!-- <a [ngClass]="{'active': notificationsVisible}" (click)="interactWithNotifications()" class="notifications">
            <hw-icon icon="farBell"></hw-icon>
        </a> -->
        <div class="user-drawer">
            <a (click)="toggleUserDrawer()" class="tile" [ngClass]="{'visible': userDrawerVisible}">
                <div class="image" [ngStyle]="{'background-image': 'url('+ (token?.picture || defaultPicture) +')'}">
                </div>
                <div class="chevron">
                    <hw-icon icon="fasChevronDown"></hw-icon>
                </div>
            </a>
        </div>
    </div>
</div>