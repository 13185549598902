import { IconComponent } from '@amaris/lib-highway/components/icon';
import { DateAgoPipe } from '@amaris/lib-highway/pipes';
import { Component, Input } from '@angular/core';

@Component({
  selector: 'notification-item',
  standalone: true,
  imports: [IconComponent, DateAgoPipe],
  templateUrl: './notification-item.component.html',
  styleUrl: './notification-item.component.scss',
})
export class NotificationItemComponent {
  @Input() icon: string;
  @Input() title: string;
  @Input() date: Date;
}
