@if (formControl) {
    @if(patients.length > 0 && formControl.value.length > 0 && inputFocused){
        <div class="search-content">
            @for (patient of patients; track patient.id) {
                <a class="search-suggestion" (click)="patientClicked(patient.id)">
                    {{patient.firstname}} {{patient.lastname.toUpperCase()}}
                </a>
            }
        </div>
    }
    <hw-text-input (inputFocused)="inputInteracted($event)" [placeholder]="placeholder" [name]="name" [formControl]="formControl" rightIcon="fasMagnifyingGlass"></hw-text-input>
} @else {
    <span class="error">Please Add a FormControl to this input</span>
}
