import { Injectable } from '@angular/core';
import { Router, CanActivate } from '@angular/router';
import { Observable, map } from 'rxjs';
import { DataStoreService } from '../data-store/data-store.service';
import { UserSubscription } from '../../models/user-subscription.model';

@Injectable()
export class ActiveSubscriptionGuardService implements CanActivate {
  constructor(
    public router: Router,
    public dataStoreService: DataStoreService,
  ) {}

  canActivate(): Observable<boolean> | boolean {
    if (this.dataStoreService?.connectedUserSubscription$?.value) {
      if (!this.dataStoreService?.connectedUserSubscription$?.value?.active) {
        this.router.navigate(['/profile']);
        return false;
      }

      return true;
    }

    return this.dataStoreService?.connectedUserSubscriptionEventEmitted.pipe(map((subscription: UserSubscription) => {
      console.log('checking...', subscription);
      if (!subscription?.active) {
        this.router.navigate(['/profile']);
        return false;
      }
      return true;
    }));
  }

  // canActivate(): Promise<boolean> {
  //   return new Promise((resolve) => {
  //     if (this.dataStoreService?.connectedUserSubscription$?.value) {
  //       if (!this.dataStoreService?.connectedUserSubscription$?.value?.active) {
  //         this.router.navigate(['/profile']);
  //         resolve(false);
  //       }
  //       resolve(true);
  //     } else {
  //       this.dataStoreService?.connectedUserSubscription$.subscribe((subscription) => {
  //         console.log('checking subscribe...', subscription);
  //         if (!subscription?.active) {
  //           console.log('redirecting to profile...');
  //           this.router.navigate(['/profile']);
  //           resolve(false);
  //         }
  //         resolve(true);
  //       });
  //     }
  //   });
  // }
}
