import {
  Component, Input, OnInit,
} from '@angular/core';
import { TextInputComponent } from '@amaris/lib-highway/components/forms/text-input';
import { FormControl } from '@angular/forms';
import {
  Router, RouterLink, RouterLinkActive, RouterOutlet,
} from '@angular/router';
import { debounceTime } from 'rxjs';
import { Patient } from '../../../models/patient.model';
import { PatientsApiService } from '../../../services/apis/patients-api/patients-api.service';

@Component({
  selector: 'app-wide-search-input',
  standalone: true,
  imports: [TextInputComponent, RouterOutlet, RouterLink, RouterLinkActive],
  templateUrl: './app-wide-search-input.component.html',
  styleUrl: './app-wide-search-input.component.scss',
})
export class AppWideSearchInputComponent implements OnInit {
  @Input() name: string;
  @Input() placeholder: string;

  public patients: Patient[] = [];
  public formControl: FormControl = new FormControl();
  public inputFocused: boolean = false;

  constructor(
    private readonly patientApiService: PatientsApiService,
    private readonly router: Router,
  ) {}

  ngOnInit(): void {
    this.formControl.valueChanges.pipe(debounceTime(250))
      .subscribe((value: string) => {
        this.patientApiService.getPatientsFiltered(value).subscribe((patients: Patient[]) => {
          this.patients = patients;
        });
      });
  }

  patientClicked(patientId: string) {
    this.patients = [];
    this.formControl.setValue('');
    this.router.navigate(['/patients', patientId], { onSameUrlNavigation: 'reload' });
  }

  inputInteracted(focused: boolean) {
    if (!focused) {
      setTimeout(() => {
        this.inputFocused = focused;
      }, 250);
      return;
    }

    this.inputFocused = focused;
  }
}
