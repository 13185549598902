<div class="page-container">
  <div class="header">
    <headband [notificationsVisible]="notificationsDrawerOpen" (userInteractedWithNotifications)="toggleNotificationsDrawer()"></headband>
  </div>
  <div class="content">
    @if ((dataStore.connectedUser$ | async)?.activeSubscription?.active) {
      <div class="menu" [ngClass]="{'mobile-open': (dataStore.mobileMenuVisible$ | async)}">
        <navigation></navigation>
      </div>
    }
    <div class="detail">
      <router-outlet></router-outlet>
    </div>
    <div [ngClass]="{'open': notificationsDrawerOpen, 'over': drawerType === 'over'}" class="push-drawer">
      <div class="notification-content">
        <div style="margin-bottom: 10px;">Notifications</div>
        <div class="notification-items">
          @for (notification of notifications; track $index) {
            <notification-item class="notification-items" [icon]="notification.icon"
              [title]="notification.title" [date]="notification.time"></notification-item>
          }
        </div>
      </div>
    </div>
  </div>
</div>
@if (displaySpinner) {
  <div class="login-loader">
    <div class="centrer">
      <span class="loader"></span>
      <div class="text">
        {{ 'core.keywords.data_loading' | translate }}
      </div>
    </div>
  </div>
}

@if (emailNotVerified) {
  <div class="login-loader">
    <div class="centrer">
      <hw-icon icon="fasExclamationTriangle"></hw-icon>
      <div class="text">
        {{ 'core.keywords.email_not_verified' | translate }}
      </div>
      <rounded-button [inverted]="true" (click)="reload()" [label]="'core.keywords.reload' | translate"></rounded-button>
      <rounded-button [inverted]="true" (click)="logout()" [label]="'core.keywords.logout' | translate"></rounded-button>
      <!-- <rounded-button [inverted]="true" (click)="resendVerificationEmail()" [label]="'core.keywords.resend_email_validation_link' | translate"></rounded-button> -->
    </div>
  </div>
}

@if ((dataStore.paymentAwaitingValidation$ | async)) {
  <div class="login-loader">
    <div class="centrer">
      <div class="icon payment">
        <img src="assets/placeholders/Logo_PLAIAD_vertical_white.svg" />
      </div>
      <div class="text" [innerHTML]="('core.keywords.payment_awaiting_validation' | translate) | safeHtml"></div>
      <rounded-button [inverted]="true" (click)="reopenPaymentValidationWindow()" [label]="'core.keywords.open_payment_validation_widow' | translate"></rounded-button>
    </div>
  </div>
}