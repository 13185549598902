import {
  Component, OnInit,
} from '@angular/core';
import { AsyncPipe, CommonModule } from '@angular/common';
import { Router, RouterOutlet } from '@angular/router';
import { HttpClientModule } from '@angular/common/http';
import { IconComponent } from '@amaris/lib-highway/components/icon';
import { SafeHtmlPipe, TranslatePipe } from '@amaris/lib-highway/pipes';
import { AuthentificationService } from '@amaris/lib-highway/services';
import { TokenModel } from '@amaris/lib-highway/models';
import { AuthService, User as OAuthUser } from '@auth0/auth0-angular';
import { NavigationComponent } from './components/interface/navigation/navigation.component';
import { HeadbandComponent } from './components/interface/headband/headband.component';
import { NotificationsService } from './services/notifications/notifications.service';
import { DrawerType } from './models/drawer.type';
import { NotificationItemComponent } from './components/design/notification-item/notification-item.component';
import { Notification } from './models/notification.model';
import { UserInitializationService } from './services/user-initialization/user-initialization.service';
import { RoundedButtonComponent } from './components/design/rounded-button/rounded-button.component';
import { User } from './models/user.model';
import { UsersApiService } from './services/apis/users-api/users-api.service';
import { OAuthMailVerificationResponse } from './models/oAuth.model';
import { DataStoreService } from './services/data-store/data-store.service';

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [
    CommonModule,
    RouterOutlet,
    NavigationComponent,
    HeadbandComponent,
    HttpClientModule,
    IconComponent,
    NotificationItemComponent,
    TranslatePipe,
    RoundedButtonComponent,
    AsyncPipe,
    SafeHtmlPipe,
  ],
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss',
})
export class AppComponent implements OnInit {
  public notificationsDrawerOpen: boolean = false;
  public drawerType: DrawerType = 'auto';
  public emailNotVerified: boolean = false;

  public displaySpinner: boolean = false;

  public notifications: Notification[] = [];

  private userLoaded: boolean = false;
  private minimalPatientsLoaded: boolean = false;

  public dataStore: DataStoreService = this.dataStoreService;

  constructor(
    private readonly notificationsService: NotificationsService,
    private readonly router: Router,
    private readonly authService: AuthService,
    private readonly authentificationService: AuthentificationService,
    private readonly userInitializationService: UserInitializationService,
    private readonly usersApiService: UsersApiService,
    private readonly dataStoreService: DataStoreService,
  ) {}

  ngOnInit(): void {
    this.notificationsService.drawerTypeChanged.subscribe((type: DrawerType) => {
      this.drawerType = type;
    });

    this.notificationsService.notificationRecieved.subscribe((notifications) => {
      this.notifications = notifications;
    });

    this.authentificationService.onTokenChanged.subscribe((token: TokenModel) => {

    });

    // this.notificationsService.newNotification();

    const currentPage = this.router.config.find((route) => route.path === window.location.pathname.replace('/', ''));

    if (currentPage?.canActivate) {
      this.displaySpinner = true;
    }

    this.dataStoreService.minimalPatients$.subscribe((patients) => {
      this.minimalPatientsLoaded = true;
      if (patients.length) {
        if (this.userLoaded) {
          this.displaySpinner = false;
        }
      }
    });

    this.userInitializationService.userInitialized.subscribe((user: User) => {
      if (user) {
        this.userLoaded = true;
        if (this.minimalPatientsLoaded) {
          this.displaySpinner = false;
        }
      }
    });

    this.userInitializationService.oAuthUserInitialized.subscribe((oAuthUser: OAuthUser) => {
      this.emailNotVerified = !oAuthUser?.email_verified;
      // if (window.location.origin.includes('localhost')) {
      //   this.emailNotVerified = false;
      // }
      if (this.emailNotVerified) {
        this.displaySpinner = false;
      }
    });
  }

  toggleNotificationsDrawer() {
    this.notificationsDrawerOpen = !this.notificationsDrawerOpen;
  }

  logout() {
    this.authentificationService.clearToken();
    this.authService.logout({
      logoutParams: {
        returnTo: `${window.location.origin}`,
      },
    });
  }

  reload() {
    window.location.reload();
  }

  resendVerificationEmail() {
    this.usersApiService.sendVerificationEmail().subscribe((response: OAuthMailVerificationResponse) => {
      // console.log(response);
    });
  }

  reopenPaymentValidationWindow() {
    if (this.dataStoreService.paymentAwaitingValidation$.value) {
      window.open(
        this.dataStoreService.paymentAwaitingValidation$.value,
        'myWindow',
        'width=500,height=800',
      );
    }
  }
}
