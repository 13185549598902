<div class="notification-item-container">
    <div class="notification-item-content">
        <div class="icon">
            <hw-icon [icon]="icon" ></hw-icon>
        </div>
        <div class="details">
            <div class="title">
                {{title}}
            </div>
            <div class="status">
                <hw-icon icon="fasClock" ></hw-icon>
                <div class="status-info">{{ date | dateAgo }}</div>
            </div>
        </div>
        <button class="icon-button">
            <hw-icon icon="fasX" ></hw-icon>
        </button>
    </div>
</div>
