/* eslint-disable max-len */
export const desktopLogo = `
<svg id="Capa_2" data-name="Capa 2" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 312.3 119.5">
  <defs>
    <style>
      .cls-1 {
        fill: url(#Nueva_muestra_de_degradado_2);
      }

      .cls-1, .cls-2 {
        stroke-width: 0px;
      }

      .cls-2 {
        fill: #222353;
      }
    </style>
    <linearGradient id="Nueva_muestra_de_degradado_2" data-name="Nueva muestra de degradado 2" x1="15" y1="-.1" x2="91.2" y2="112.3" gradientUnits="userSpaceOnUse">
      <stop offset=".2" stop-color="#6f246c"/>
      <stop offset=".9" stop-color="#b94268"/>
    </linearGradient>
  </defs>
  <g id="Capa_1-2" data-name="Capa 1">
    <g>
      <g>
        <path class="cls-2" d="M140.9,66.8c-4.7,0-8.8-1-12.3-3.1-3.6-2-6.4-4.9-8.4-8.5-2-3.6-3.1-7.7-3.1-12.4s1-8.9,3.1-12.5c2-3.6,4.8-6.4,8.4-8.5,3.6-2,7.7-3.1,12.3-3.1s8.7,1,12.3,3.1c3.6,2,6.4,4.9,8.4,8.5,2,3.6,3.1,7.8,3.1,12.5s-1,8.8-3,12.4c-2,3.6-4.8,6.4-8.4,8.5-3.6,2-7.7,3.1-12.3,3.1ZM140.9,59.1c3,0,5.6-.7,7.9-2.1,2.3-1.4,4.1-3.3,5.4-5.7,1.3-2.4,1.9-5.2,1.9-8.4s-.6-6-1.9-8.4c-1.3-2.4-3.1-4.4-5.4-5.7-2.3-1.4-5-2.1-7.9-2.1s-5.6.7-7.9,2.1c-2.3,1.4-4.1,3.3-5.4,5.7-1.3,2.4-2,5.3-2,8.4s.7,6,2,8.4c1.3,2.4,3.1,4.3,5.4,5.7,2.3,1.4,4.9,2.1,7.9,2.1Z"/>
        <path class="cls-2" d="M193.8,66.8c-4.6,0-8.7-1.1-12.3-3.2-3.6-2.1-6.4-4.9-8.5-8.5-2-3.6-3.1-7.7-3.1-12.2s1-8.8,3-12.4c2-3.6,4.8-6.5,8.3-8.5,3.5-2,7.5-3.1,12.1-3.1s6.5.6,9.3,1.9c2.8,1.3,5.4,3.2,7.6,5.8.8.9,1.1,1.9.9,2.8-.2,1-.9,1.8-1.9,2.6-.8.6-1.7.8-2.7.6-1-.2-1.9-.7-2.7-1.5-2.8-3-6.3-4.5-10.5-4.5s-5.5.7-7.8,2c-2.2,1.4-4,3.2-5.3,5.7-1.3,2.4-1.9,5.3-1.9,8.5s.6,5.9,1.9,8.3c1.3,2.4,3.1,4.3,5.4,5.7,2.3,1.4,5,2.1,8,2.1s3.8-.2,5.3-.7c1.5-.5,2.9-1.3,4.2-2.3.9-.7,1.9-1.2,2.8-1.3,1,0,1.8.2,2.6.8,1,.8,1.5,1.7,1.6,2.7.1,1-.2,1.9-1,2.7-4.1,3.9-9.3,5.8-15.5,5.8Z"/>
        <g>
          <path class="cls-2" d="M239.8,13.9c1,0,1.8-.3,2.4-1l6.8-6.8c.4-.3.6-.7.8-1.1.1-.4.2-.9.2-1.3,0-1-.3-1.9-1-2.6C248.4.3,247.5,0,246.4,0s-1.8.3-2.5,1l-6.9,6.9c-.6.6-.8,1.4-.8,2.4s.3,1.9,1,2.6c.7.7,1.5,1,2.6,1Z"/>
          <path class="cls-2" d="M258.3,44.9c.7-.7,1.1-1.6,1.1-2.7,0-4.5-.9-8.6-2.5-12-1.7-3.5-4.2-6.2-7.4-8.2-3.2-2-7.1-3-11.6-3s-8.6,1-12.1,3.1c-3.5,2-6.3,4.9-8.2,8.5-2,3.6-3,7.8-3,12.5s1,8.8,3.2,12.4c2.1,3.6,5,6.4,8.7,8.5s8,3.1,12.7,3.1,5.4-.5,8.3-1.5c2.9-1,5.3-2.2,7.3-3.8,1-.7,1.4-1.6,1.4-2.7,0-1-.6-2-1.6-2.8-.7-.6-1.6-.9-2.6-.9-1,0-1.9.4-2.8,1-1.2.9-2.7,1.6-4.5,2.3-1.9.7-3.7,1-5.5,1-3.3,0-6.2-.7-8.7-2.2-2.5-1.4-4.5-3.4-6-5.9-1-1.7-1.6-3.5-1.9-5.4h32.8c1.2,0,2.1-.4,2.8-1.1ZM229.5,28.5c2.3-1.4,5.1-2.2,8.4-2.2s5.3.7,7.3,2c2,1.3,3.6,3.1,4.7,5.4.8,1.6,1.3,3.4,1.5,5.3h-28.7c.3-1.6.8-3.2,1.5-4.6,1.2-2.5,3-4.5,5.3-5.9Z"/>
        </g>
        <path class="cls-2" d="M309.1,30.7c-2.1-3.6-4.9-6.5-8.5-8.6-3.6-2.1-7.6-3.2-12.1-3.2s-8.5,1.1-12.1,3.2c-3.6,2.1-6.4,5-8.5,8.6-2.1,3.6-3.2,7.7-3.2,12.3s1,8.6,2.9,12.2c1.9,3.6,4.6,6.4,7.9,8.5,3.3,2.1,7.1,3.2,11.3,3.2s8-1.1,11.3-3.2c2.1-1.3,3.8-2.9,5.4-4.8v3.3c0,1.3.4,2.3,1.3,3.2.8.8,1.9,1.3,3.2,1.3s2.3-.4,3.2-1.3c.8-.8,1.3-1.9,1.3-3.2v-19.2c0-4.5-1.1-8.6-3.2-12.3ZM301.8,51.2c-1.3,2.4-3.1,4.4-5.4,5.8-2.3,1.4-4.9,2.1-7.9,2.1s-5.5-.7-7.8-2.1c-2.3-1.4-4.2-3.3-5.5-5.8-1.4-2.4-2-5.2-2-8.2s.7-5.9,2-8.3c1.4-2.4,3.2-4.4,5.5-5.8,2.3-1.4,4.9-2.1,7.8-2.1s5.6.7,7.9,2.1c2.3,1.4,4.1,3.3,5.4,5.8,1.3,2.4,2,5.2,2,8.3s-.7,5.8-2,8.2Z"/>
      </g>
      <path class="cls-1" d="M76.5,81.5c.2-7.1,3.4-12.3,9.7-15.7,4.4-2.3,5.3-7.9,1.7-11.2-.5-.4-1.1-.8-1.7-1.1-6.7-3.5-10-8.9-9.8-16.4,0-2.9-1-5.1-3.5-6.5-2.6-1.5-5.1-1.2-7.5.4-6.4,4.4-13.8,4.4-20.2,0-2.5-1.7-5-1.9-7.6-.4-2.5,1.5-3.5,3.8-3.4,6.8.1,7.1-3,12.5-9.4,15.9-3.5,1.9-4.9,5.6-3.4,9,.7,1.7,2,2.8,3.6,3.6,9.6,5.2,12.1,17.5,5.3,25.9-4.5,5.6-12.1,7.7-18.9,5.3C4.7,94.8,0,88.4.1,81.3c0-7,3.3-12.1,9.4-15.4,4.5-2.4,5.3-8.1,1.6-11.4-.5-.5-1.1-.8-1.8-1.2C2.7,49.8-.9,42.8.2,35.6c1.1-7.3,6.6-13,14-14.3,4.6-.8,8.9,0,12.8,2.8,2.4,1.7,4.9,2,7.5.7,2.6-1.4,3.8-3.6,3.7-6.6-.4-9,5.7-16,13.3-17.7,11.5-2.5,21.1,6.2,21.1,16.5,0,1,0,1.9,0,2.9.9,5,6.5,7.4,10.7,4.6,3.3-2.3,6.9-3.4,11-3.3,8,.1,15.4,6.6,16.4,14.5,1,7.7-2.4,14.1-9.2,17.8-2.5,1.3-3.9,3.4-3.9,6.2,0,2.9,1.4,5,3.9,6.3,5.5,3,8.7,7.6,9.3,13.8.9,8.6-5.7,17.1-14.4,18.5-4.7.7-8.9-.2-12.8-2.9-2.4-1.6-4.9-2-7.4-.6-2.6,1.4-3.9,3.6-3.7,6.5.3,7.1-2.7,12.6-8.8,16.1-10.3,5.9-23.7-.9-25.1-12.6-.9-7.5,2.5-14.2,9.2-17.7,2.5-1.3,3.9-3.4,3.9-6.2,0-2.9-1.4-5-3.9-6.3-5.7-3-8.8-7.7-9.4-14.1-.8-8.4,5.4-16.4,13.7-17.9,7.1-1.3,14.2,1.8,17.9,7.9,3.7,6.1,3.3,13.8-1.1,19.4-1.5,1.9-3.3,3.5-5.5,4.6-2.7,1.3-4.2,3.4-4.2,6.4,0,3,1.5,5.1,4.2,6.4.7.3,1.4.8,2,1.2,2.4,1.7,4.9,1.9,7.4.5,2.6-1.4,3.7-3.7,3.7-6.6,0-.3,0-.6,0-.9Z"/>
      <g>
        <g>
          <path class="cls-2" d="M171.6,106.1c-.5,0-.9-.2-1.2-.5-.3-.3-.5-.7-.5-1.2v-10.9c0-1.7.4-3.3,1.2-4.7.8-1.4,1.9-2.5,3.2-3.3,1.4-.8,2.9-1.2,4.6-1.2s3.3.4,4.7,1.2c1.4.8,2.4,1.9,3.2,3.3.8,1.4,1.2,2.9,1.2,4.7s-.4,3.3-1.1,4.7c-.7,1.4-1.7,2.5-3,3.3-1.3.8-2.7,1.2-4.3,1.2s-2.5-.3-3.6-.8c-1.1-.5-2-1.3-2.8-2.2v4.7c0,.5-.2.9-.5,1.2-.3.3-.7.5-1.2.5ZM179,99.7c1.1,0,2.1-.3,3-.8.9-.5,1.6-1.3,2.1-2.2.5-.9.8-2,.8-3.1s-.3-2.2-.8-3.2c-.5-.9-1.2-1.7-2.1-2.2-.9-.5-1.9-.8-3-.8s-2.1.3-3,.8c-.9.5-1.6,1.3-2.1,2.2-.5.9-.8,2-.8,3.2s.3,2.2.8,3.1c.5.9,1.2,1.7,2.1,2.2.9.5,1.9.8,3,.8Z"/>
          <path class="cls-2" d="M192.2,73.5c-.3-.3-.7-.5-1.2-.5s-.9.2-1.2.5c-.3.3-.5.7-.5,1.2v26.5c0,.5.2.9.5,1.2.3.3.7.5,1.2.5s.9-.2,1.2-.5c.3-.3.5-.7.5-1.2v-26.5c0-.5-.2-.9-.5-1.2Z"/>
          <path class="cls-2" d="M211.4,88.9c-.8-1.4-1.9-2.5-3.2-3.3-1.4-.8-2.9-1.2-4.6-1.2s-3.3.4-4.6,1.2c-1.4.8-2.5,1.9-3.3,3.3-.8,1.4-1.2,2.9-1.2,4.7s.4,3.3,1.1,4.7c.7,1.4,1.7,2.5,3,3.3,1.3.8,2.7,1.2,4.3,1.2s3-.4,4.3-1.2c.8-.5,1.5-1.1,2-1.8v1.3c0,.5.2.9.5,1.2.3.3.7.5,1.2.5s.9-.2,1.2-.5c.3-.3.5-.7.5-1.2v-7.4c0-1.7-.4-3.3-1.2-4.7ZM208.7,96.7c-.5.9-1.2,1.7-2.1,2.2-.9.5-1.9.8-3,.8s-2.1-.3-3-.8-1.6-1.3-2.1-2.2c-.5-.9-.8-2-.8-3.1s.3-2.2.8-3.2c.5-.9,1.2-1.7,2.1-2.2.9-.5,1.9-.8,3-.8s2.1.3,3,.8c.9.5,1.6,1.3,2.1,2.2.5.9.8,2,.8,3.2s-.3,2.2-.8,3.1Z"/>
          <path class="cls-2" d="M216,102.6c-.5,0-.9-.2-1.2-.5-.3-.3-.5-.7-.5-1.2v-14.7c0-.5.2-.9.5-1.2.3-.3.7-.5,1.2-.5s.9.2,1.2.5c.3.3.5.7.5,1.2v14.7c0,.5-.2.9-.5,1.2-.3.3-.7.5-1.2.5Z"/>
          <path class="cls-2" d="M236.5,88.9c-.8-1.4-1.9-2.5-3.2-3.3-1.4-.8-2.9-1.2-4.6-1.2s-3.3.4-4.6,1.2c-1.4.8-2.5,1.9-3.3,3.3-.8,1.4-1.2,2.9-1.2,4.7s.4,3.3,1.1,4.7c.7,1.4,1.7,2.5,3,3.3,1.3.8,2.7,1.2,4.3,1.2s3-.4,4.3-1.2c.8-.5,1.5-1.1,2-1.8v1.3c0,.5.2.9.5,1.2.3.3.7.5,1.2.5s.9-.2,1.2-.5c.3-.3.5-.7.5-1.2v-7.4c0-1.7-.4-3.3-1.2-4.7ZM233.7,96.7c-.5.9-1.2,1.7-2.1,2.2-.9.5-1.9.8-3,.8s-2.1-.3-3-.8c-.9-.5-1.6-1.3-2.1-2.2-.5-.9-.8-2-.8-3.1s.3-2.2.8-3.2c.5-.9,1.2-1.7,2.1-2.2.9-.5,1.9-.8,3-.8s2.1.3,3,.8c.9.5,1.6,1.3,2.1,2.2.5.9.8,2,.8,3.2s-.3,2.2-.8,3.1Z"/>
          <path class="cls-2" d="M247.8,102.7c-1.7,0-3.3-.4-4.6-1.2-1.4-.8-2.5-1.9-3.3-3.3-.8-1.4-1.2-2.9-1.2-4.7s.4-3.3,1.1-4.7c.7-1.4,1.7-2.5,3-3.3,1.3-.8,2.7-1.2,4.3-1.2s2.5.3,3.6.8c1.1.5,2,1.3,2.8,2.2v-12.7c0-.5.2-.9.5-1.2.3-.3.7-.5,1.2-.5s.9.2,1.2.5c.3.3.5.7.5,1.2v18.8c0,1.7-.4,3.3-1.2,4.7-.8,1.4-1.9,2.5-3.2,3.3-1.4.8-2.9,1.2-4.6,1.2ZM247.8,99.7c1.1,0,2.1-.3,3-.8.9-.5,1.6-1.3,2.1-2.2.5-.9.8-2,.8-3.2s-.3-2.2-.8-3.2c-.5-.9-1.2-1.7-2.1-2.2-.9-.5-1.9-.8-3-.8s-2.1.3-3,.8c-.9.5-1.6,1.3-2.1,2.2-.5.9-.8,2-.8,3.2s.3,2.2.8,3.2c.5.9,1.2,1.7,2.1,2.2s1.9.8,3,.8Z"/>
        </g>
        <path class="cls-2" d="M217.9,77c-.9,0-1.7-.6-1.9-1.4,0-.2-.2-.3-.4-.3s-.3.1-.4.3c-.3.8-1.1,1.4-1.9,1.4s-2-.9-2-2,.9-2,2-2,1.7.6,1.9,1.4c0,.2.2.3.4.3h0c.2,0,.3-.1.4-.3.3-.8,1.1-1.4,1.9-1.4s2,.9,2,2-.9,2-2,2Z"/>
      </g>
      <g>
        <path class="cls-2" d="M146,95.5c-1.1,0-2.2-.3-3.1-.8-.9-.5-1.6-1.3-2.2-2.2s-.8-2-.8-3.1v-10c0-.3.1-.6.3-.8.2-.2.5-.3.8-.3s.6.1.8.3c.2.2.3.5.3.8v5.9c.5-.6,1.1-1.1,1.9-1.5.7-.4,1.5-.5,2.4-.5s2,.3,2.9.8c.9.5,1.5,1.3,2,2.2.5.9.7,2,.7,3.1s-.3,2.2-.8,3.1-1.3,1.7-2.2,2.2c-.9.5-1.9.8-3.1.8ZM146,93.6c.7,0,1.4-.2,2-.5.6-.4,1.1-.9,1.4-1.5.3-.6.5-1.3.5-2.1s-.2-1.5-.5-2.1-.8-1.1-1.4-1.5c-.6-.4-1.3-.5-2-.5s-1.4.2-2,.5-1.1.8-1.4,1.5c-.3.6-.5,1.3-.5,2.1s.2,1.5.5,2.1c.3.6.8,1.1,1.4,1.5.6.4,1.3.5,2,.5Z"/>
        <path class="cls-2" d="M163.9,83.5c-.6-.3-1.1,0-1.4.5l-3.5,8-4-8c-.1-.3-.3-.5-.6-.6-.2,0-.5,0-.8,0-.3.1-.5.3-.6.6-.1.3,0,.5,0,.8l4.9,9.4-2.2,5c-.3.6-.1,1.1.5,1.4.2,0,.4.1.5.1.4,0,.7-.2.9-.7l6.7-15.1c.3-.6.1-1.1-.6-1.4Z"/>
      </g>
    </g>
  </g>
</svg>
`;

export const mobileLogo = `
<svg id="Capa_2-mobile" data-name="Capa 2" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 119.5 119.5">
  <defs>
    <style>
      .cls-1-mobile {
        fill: url(#Nueva_muestra_de_degradado_2-mobile);
      }

      .cls-1-mobile {
        stroke-width: 0px;
      }
    </style>
    <linearGradient id="Nueva_muestra_de_degradado_2-mobile" data-name="Nueva muestra de degradado 2" x1="15" y1="-.1" x2="91.2" y2="112.3" gradientUnits="userSpaceOnUse">
      <stop offset=".2" stop-color="#6f246c"></stop>
      <stop offset=".9" stop-color="#b94268"></stop>
    </linearGradient>
  </defs>
  <g id="Capa_1-2-mobile" data-name="Capa 1">
    <g>
      
      <path class="cls-1-mobile" d="M76.5,81.5c.2-7.1,3.4-12.3,9.7-15.7,4.4-2.3,5.3-7.9,1.7-11.2-.5-.4-1.1-.8-1.7-1.1-6.7-3.5-10-8.9-9.8-16.4,0-2.9-1-5.1-3.5-6.5-2.6-1.5-5.1-1.2-7.5.4-6.4,4.4-13.8,4.4-20.2,0-2.5-1.7-5-1.9-7.6-.4-2.5,1.5-3.5,3.8-3.4,6.8.1,7.1-3,12.5-9.4,15.9-3.5,1.9-4.9,5.6-3.4,9,.7,1.7,2,2.8,3.6,3.6,9.6,5.2,12.1,17.5,5.3,25.9-4.5,5.6-12.1,7.7-18.9,5.3C4.7,94.8,0,88.4.1,81.3c0-7,3.3-12.1,9.4-15.4,4.5-2.4,5.3-8.1,1.6-11.4-.5-.5-1.1-.8-1.8-1.2C2.7,49.8-.9,42.8.2,35.6c1.1-7.3,6.6-13,14-14.3,4.6-.8,8.9,0,12.8,2.8,2.4,1.7,4.9,2,7.5.7,2.6-1.4,3.8-3.6,3.7-6.6-.4-9,5.7-16,13.3-17.7,11.5-2.5,21.1,6.2,21.1,16.5,0,1,0,1.9,0,2.9.9,5,6.5,7.4,10.7,4.6,3.3-2.3,6.9-3.4,11-3.3,8,.1,15.4,6.6,16.4,14.5,1,7.7-2.4,14.1-9.2,17.8-2.5,1.3-3.9,3.4-3.9,6.2,0,2.9,1.4,5,3.9,6.3,5.5,3,8.7,7.6,9.3,13.8.9,8.6-5.7,17.1-14.4,18.5-4.7.7-8.9-.2-12.8-2.9-2.4-1.6-4.9-2-7.4-.6-2.6,1.4-3.9,3.6-3.7,6.5.3,7.1-2.7,12.6-8.8,16.1-10.3,5.9-23.7-.9-25.1-12.6-.9-7.5,2.5-14.2,9.2-17.7,2.5-1.3,3.9-3.4,3.9-6.2,0-2.9-1.4-5-3.9-6.3-5.7-3-8.8-7.7-9.4-14.1-.8-8.4,5.4-16.4,13.7-17.9,7.1-1.3,14.2,1.8,17.9,7.9,3.7,6.1,3.3,13.8-1.1,19.4-1.5,1.9-3.3,3.5-5.5,4.6-2.7,1.3-4.2,3.4-4.2,6.4,0,3,1.5,5.1,4.2,6.4.7.3,1.4.8,2,1.2,2.4,1.7,4.9,1.9,7.4.5,2.6-1.4,3.7-3.7,3.7-6.6,0-.3,0-.6,0-.9Z"></path>
      
      
    </g>
  </g>
</svg>
`;
