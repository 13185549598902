<nav class="navigation-container">
    <ul class="navigation-list">
        @for (item of menuItems; track $index) {
            @if (item.tooltip) {
                <li class="navigation-item" [tooltip]="item.tooltip" [ngClass]="{'disabled': item.disabled}" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: $index === 0 }">
                    <a class="navigation-link" [routerLink]="item.link" ariaCurrentWhenActive="page" (click)="closeMobileMenu()" [id]="item.id">
                        <hw-icon [icon]="item.icon"></hw-icon>
                        <span class="show-mobile">{{ item.tooltip | translate }}</span>
                    </a>
                </li>
            } @else {
                <li class="navigation-item" [ngClass]="{'disabled': item.disabled}" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: $index === 0 }">
                    <a class="navigation-link" [routerLink]="item.link" ariaCurrentWhenActive="page" (click)="closeMobileMenu()" [id]="item.id">
                        <hw-icon [icon]="item.icon"></hw-icon>
                        <span class="show-mobile">{{ item.tooltip | translate }}</span>
                    </a>
                </li>
            }
          }
    </ul>
</nav>