import { ApplicationConfig, importProvidersFrom } from '@angular/core';
import { provideRouter } from '@angular/router';
import { provideHttpClient, withInterceptors } from '@angular/common/http';
import { provideAuth0 } from '@auth0/auth0-angular';
import { httpInterceptorFn } from '@amaris/lib-highway/services';
import { provideToastr } from 'ngx-toastr';
import { provideNgxStripe } from 'ngx-stripe';
import { provideAnimations } from '@angular/platform-browser/animations';
import { routes } from './app.routes';
import { CoreModule } from './core.module';
import { environment } from '../environments/environment';

export const appConfig: ApplicationConfig = {
  providers: [
    provideRouter(routes),
    provideHttpClient(withInterceptors([httpInterceptorFn])),
    importProvidersFrom(CoreModule),
    provideAuth0(environment.oAuthProvider),
    provideAnimations(),
    provideToastr(),
    provideNgxStripe(),
  ],
};
