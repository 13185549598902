import { LOCALE_ID, NgModule } from '@angular/core';
import {
  ConfigurationService,
  EnvironmentService,
  LoggerService,
  RedirectService,
  StorageService,
  TranslationService,
} from '@amaris/lib-highway/services';
import { LogLevelEnum, StorageKeysEnum } from '@amaris/lib-highway/enums';
import localeFr from '@angular/common/locales/fr';
import { registerLocaleData } from '@angular/common';
import moment from 'moment';
import 'moment/locale/fr';
import { ToastrService } from 'ngx-toastr';
import { UserInitializationService } from './services/user-initialization/user-initialization.service';
import { AntecedentsApiService } from './services/apis/antecedents-api/antecedents-api.service';
import { ConsultationsApiService } from './services/apis/consultations-api/consultations-api.service';
import { PatientsApiService } from './services/apis/patients-api/patients-api.service';
import { PaymentApiService } from './services/apis/payment-api/payment-api.service';
import { ActiveSubscriptionGuardService } from './services/authguard/active-subscription.guard';
import { environment } from '../environments/environment';

@NgModule({
  providers: [
    { provide: 'environment', useValue: environment },
    ConfigurationService,
    { provide: LOCALE_ID, useValue: 'fr' },
    ActiveSubscriptionGuardService,
  ],
})

export class CoreModule {
  private readonly translationCacheVersion: number = 3;
  constructor(
      private readonly translationService: TranslationService,
      private readonly environmentService: EnvironmentService,
      private readonly storageService: StorageService,
      private readonly loggerService: LoggerService,
      private readonly redirectService: RedirectService,
      private readonly userInitializationService: UserInitializationService,
      private readonly antecedentsApiService: AntecedentsApiService,
      private readonly consultationsApiService: ConsultationsApiService,
      private readonly toastrService: ToastrService,
      private readonly patientsApiService: PatientsApiService,
      private readonly paymentApiService: PaymentApiService,
  ) {
    this.userInitializationService.init();
    registerLocaleData(localeFr, 'fr');
    this.environmentService.setEnvironment(environment.production);
    const selectedLanguage: string = this.storageService.getLocalStorageItem(StorageKeysEnum.SelectedLanguage, 'fr');
    this.translationService.initTranslation('/assets/i18n/', selectedLanguage, environment.name, this.translationCacheVersion);

    this.loggerService.setLogLevel(<LogLevelEnum>environment.logLevel);

    // maybe activate this after login instead of at application load for security purposes
    // (and i guess i will need a token to open the connection :) )
    // this.signalRService.startConnection(environment.signalRUrl);

    this.redirectService.activateRedirectURLAndLinks();

    moment.locale('fr', {
      week: {
        dow: 1,
      },
    });

    this.toastrService.toastrConfig.preventDuplicates = true;
    this.toastrService.toastrConfig.newestOnTop = true;

    // preload some data
    this.userInitializationService.userInitialized.subscribe(() => {
      this.antecedentsApiService.getAntecedentTypes().subscribe();
      this.consultationsApiService.getConsultationTypes().subscribe();
      this.consultationsApiService.getConsultationActesData().subscribe();
      this.patientsApiService.getMinimalPatientsFiltered().subscribe();
      this.paymentApiService.getSubscriptionOffers().subscribe();
    });
  }
}
