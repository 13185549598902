import { Routes } from '@angular/router';
import { AuthGuard } from '@auth0/auth0-angular';
import { ActiveSubscriptionGuardService } from './services/authguard/active-subscription.guard';

export const routes: Routes = [
  {
    path: 'abonnement',
    loadComponent: () => import('./pages/abonnement/abonnement.component').then((m) => m.AbonnementComponent),
  },
  {
    path: '',
    loadChildren: () => import('./pages/dashboard/dashboard.module').then((m) => m.DashboardModule),
    canActivate: [AuthGuard, ActiveSubscriptionGuardService],
  },
  {
    path: 'calendrier',
    loadChildren: () => import('./pages/calendar/calendar.module').then((m) => m.CalendarModule),
    canActivate: [AuthGuard, ActiveSubscriptionGuardService],
  },
  {
    path: 'patients',
    loadChildren: () => import('./pages/patients/patients.module').then((m) => m.PatientsModule),
    canActivate: [AuthGuard, ActiveSubscriptionGuardService],
  },
  {
    path: 'finance',
    loadChildren: () => import('./pages/finance/finance.module').then((m) => m.FinanceModule),
    canActivate: [AuthGuard, ActiveSubscriptionGuardService],
  },
  {
    path: 'statistiques',
    loadChildren: () => import('./pages/stats/stats.module').then((m) => m.StatsModule),
    canActivate: [AuthGuard, ActiveSubscriptionGuardService],
  },
  {
    path: 'stocks',
    loadChildren: () => import('./pages/stocks/stocks.module').then((m) => m.StocksModule),
    canActivate: [AuthGuard, ActiveSubscriptionGuardService],
  },
  {
    path: 'administration',
    loadChildren: () => import('./pages/administration/administration.module').then((m) => m.AdministrationModule),
    canActivate: [AuthGuard, ActiveSubscriptionGuardService],
  },
  {
    path: 'profile',
    loadComponent: () => import('./pages/user-profile/user-profile.component').then((m) => m.UserProfileComponent),
    canActivate: [AuthGuard],
  },
  {
    path: 'consultations',
    loadComponent: () => import('./pages/consultations/consultations.component').then((m) => m.ConsultationsComponent),
    canActivate: [AuthGuard, ActiveSubscriptionGuardService],
  },
  // {
  //   path: 'users',
  //   loadComponent: () => import('./pages/users/users.component').then((m) => m.UsersComponent),
  //   canActivate: [AuthGuard, SuperAdminGuardService],
  // },
  {
    path: '**',
    pathMatch: 'full',
    loadComponent: () => import('./pages/page-not-found/page-not-found.component').then((m) => m.PageNotFoundComponent),
    canActivate: [AuthGuard, ActiveSubscriptionGuardService],
  },
];
